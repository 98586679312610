<template>
  <div class="sub-header-thin-div">
    <div :class="isMobile ? 'p-2' : 'p-10'">
      <div class="box-white" v-if="orderList">
        <div :class="`mt-5 d-flex justify-content-between ${isMobile ? 'vertical-center' : 'mx-auto w-50 center'}`" v-if="eventInfo.tagList && eventInfo.tagList.length > 0">
          <span :class="isMobile ? 'font-15' : 'font-25'">{{ $t('tag') }}</span>
          <b-form-select class="ml-10" v-model="selectedTag">
            <b-form-select-option v-for="tag in eventInfo.tagList" :value="tag" :key="tag">{{ tag }}</b-form-select-option>
          </b-form-select>
        </div>
        <div :class="`position-relative mt-10 ${isMobile ? 'mx-2 vertical-center' : 'mx-auto w-50 center'}`">
          <i class="position-absolute fa fa-search font-20 color-purple" style="left: 10px; top: 10px;" />
          <input class="w-100 px-20 search-box" style="padding-left: 40px !important; padding-right: 40px !important;" v-model="keyword" />
          <v-btn class="position-absolute" style="right: 10px; top: 8px;" icon small @click="keyword = ''" :disabled="!keyword">
            <i :class="`font-20 flaticon-circle ${keyword.length > 0 ? 'color-blue' : ''}`"></i>
          </v-btn>
        </div>
        <div class="my-5 row">
          <div :class="`mx-auto my-2 ${!keyword && index === 0 ? 'col-xl-7' : 'col-xl-6'} col-lg-12`" v-for="(item, index) in salesList" :key="(item, index)">
            <div :class="`box-white center ${isMobile ? 'm-1' : 'm-5 px-10'}`">
              <div class="mt-5 d-flex justify-content-between pointer" @click="item.expanded = !item.expanded">
                <span :class="`font-bold color-drink ${isMobile ? 'font-15' : 'font-25'}`">
                  <img :src="item.expanded ? assets.arrow_up : assets.arrow_down" :height="isMobile ? 15 : 25" v-if="drinkList" />
                  &nbsp;
                  {{ item.employeeName }}
                </span>
                <span :class="`font-bold color-drink vertical-center ${isMobile ? 'font-15' : 'font-25'}`">
                  {{ `${getDecimalFormat(item.soldQuantity)} / ${getDecimalFormat(item.totalQuantity)}` }}
                </span>
              </div>
              <div class="ml-10 mt-5 search-box" v-if="drinkList && item.expanded">
                <div class="mt-5 d-flex justify-content-between" v-for="(drink, index2) in drinkList.filter(element => element.tag === selectedTag && element.soldQuantityMap && element.totalQuantityMap)" :key="(drink, index2)">
                  <span :class="`font-bold ${isMobile ? 'font-15' : 'font-20'}`">{{ drink.name }}</span>
                  <span :class="isMobile ? 'font-15' : 'font-20'">
                    {{ `${getDecimalFormat(item.quantityKey ? (drink.soldQuantityMap[item.quantityKey] || 0) : drink.soldQuantity)} / ${getDecimalFormat(item.quantityKey ? (drink.totalQuantityMap[item.quantityKey] || 0) : drink.totalQuantity)}` }}
                  </span>
                </div>
              </div>
              <div class="mt-5 d-flex justify-content-between">
                <span :class="`font-bold ${isMobile ? 'font-15' : 'font-25'}`">{{ $t('sales_amount') }}</span>
                <span :class="`font-bold ${isMobile ? 'font-15' : 'font-25'}`">{{ getPriceFormat(item.salesAmount) }}</span>
              </div>
              <div class="mt-2 d-flex justify-content-between">
                <span :class="`ml-5 ${isMobile ? 'font-15' : 'font-25'}`">{{ $t('paid_online') }}</span>
                <span :class="isMobile ? 'font-15' : 'font-25'">{{ getPriceFormat(item.paidOnline) }}</span>
              </div>
              <div class="mt-2 d-flex justify-content-between">
                <span :class="`ml-5 ${isMobile ? 'font-15' : 'font-25'}`">{{ $t('paid_by_card') }}</span>
                <span :class="isMobile ? 'font-15' : 'font-25'">{{ getPriceFormat(item.paidCard) }}</span>
              </div>
              <div class="mt-2 d-flex justify-content-between">
                <span :class="`ml-5 ${isMobile ? 'font-15' : 'font-25'}`">{{ $t('paid_by_cash') }}</span>
                <span :class="isMobile ? 'font-15' : 'font-25'">{{ getPriceFormat(item.paidCash) }}</span>
              </div>
              <div class="mt-5 d-flex justify-content-between">
                <span :class="`font-bold ${isMobile ? 'font-15' : 'font-25'}`">{{ $t('tip') }}</span>
                <span :class="`font-bold ${isMobile ? 'font-15' : 'font-25'}`">{{ getPriceFormat(item.tipAmount) }}</span>
              </div>
              <div class="mt-2 d-flex justify-content-between">
                <span :class="`ml-5 ${isMobile ? 'font-15' : 'font-25'}`">{{ $t('paid_online') }}</span>
                <span :class="isMobile ? 'font-15' : 'font-25'">{{ getPriceFormat(item.tipOnline) }}</span>
              </div>
              <div class="mt-2 d-flex justify-content-between">
                <span :class="`ml-5 ${isMobile ? 'font-15' : 'font-25'}`">{{ $t('paid_by_card') }}</span>
                <span :class="isMobile ? 'font-15' : 'font-25'">{{ getPriceFormat(item.tipCard) }}</span>
              </div>
              <div class="mt-2 d-flex justify-content-between">
                <span :class="`ml-5 ${isMobile ? 'font-15' : 'font-25'}`">{{ $t('paid_by_cash') }}</span>
                <span :class="isMobile ? 'font-15' : 'font-25'">{{ getPriceFormat(item.tipCash) }}</span>
              </div>
              <div v-if="!keyword && index === 0">
                <div class="mt-3 d-flex justify-content-between">
                  <span :class="`font-bold ${isMobile ? 'font-15' : 'font-25'}`">{{ $t('payout_amount') }}</span>
                  <span :class="`font-bold ${isMobile ? 'font-15' : 'font-25'}`">{{ getPriceFormat(item.payoutAmount) }}</span>
                </div>
                <div class="my-2 d-flex justify-content-between">
                  <span :class="`ml-5 ${isMobile ? 'font-15' : 'font-25'}`">{{ $t('already_paid') }}</span>
                  <span :class="isMobile ? 'font-15' : 'font-25'">{{ getPriceFormat(item.alreadyPaid) }}</span>
                </div>
                <div class="my-2 d-flex justify-content-between">
                  <span :class="`ml-5 ${isMobile ? 'font-15' : 'font-25'}`">{{ $t('pending') }}</span>
                  <span :class="isMobile ? 'font-15' : 'font-25'">{{ getPriceFormat(item.pending) }}</span>
                </div>
                <div class="mt-3 d-flex justify-content-between">
                  <span :class="`font-bold ${isMobile ? 'font-15' : 'font-25'}`">{{ $t('not_paid') }}</span>
                  <span :class="`font-bold ${isMobile ? 'font-15' : 'font-25'}`">{{ getPriceFormat(item.notPaid) }}</span>
                </div>
              </div>
              <div class="my-3 d-flex justify-content-end" v-if="item.quantityKey && item.quantityKey !== $store.state.myId">
                <v-btn icon @click="generateInvoice(item.employeeName)">
                  <img :src="assets.download" :height="isMobile ? 20 : 25" />
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1900"
        :pdf-quality="2"
        :manual-pagination="false"
        :html-to-pdf-options="htmlToPdfOptions"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        @progress="onProgress"
        ref="html2PdfInvoice">
        <section slot="pdf-content">
          <div class="d-flex flex-column" v-if="drinkList && eventInfo && invoiceInfo">
            <div class="d-flex justify-content-end">
              <img :src="assets.logo" :height="30" />
            </div>
            <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ invoiceInfo.employeeName }}</span>
            <span :class="`mt-5 ${isMobile ? 'font-12' : 'font-15'}`">{{ eventInfo.name }}</span>
            <span :class="`mt-2 ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('for_the_period') }} {{ getDateStringFromTimestamp(eventInfo.startAt, 'DD.MM.YYYY') }} - {{ getDateStringFromTimestamp(eventInfo.endAt, 'DD.MM.YYYY') }}</span>
            <span :class="`mt-2 ${isMobile ? 'font-12' : 'font-15'}`" v-if="selectedTag">{{ $t('tag') }}: {{ selectedTag }}</span>
            <div class="m-10">
              <div class="mt-5 d-flex justify-content-between" v-for="(drink, index) in drinkList.filter(element => element.tag === selectedTag && element.soldQuantityMap && element.totalQuantityMap)" :key="(drink, index)">
                <span :class="isMobile ? 'font-12' : 'font-15'">{{ drink.name }}</span>
                <span :class="isMobile ? 'font-12' : 'font-15'">
                  {{ `${getDecimalFormat(invoiceInfo.quantityKey ? (drink.soldQuantityMap[invoiceInfo.quantityKey] || 0) : drink.soldQuantity)} / ${getDecimalFormat(invoiceInfo.quantityKey ? (drink.totalQuantityMap[invoiceInfo.quantityKey] || 0) : drink.totalQuantity)}` }}
                </span>
              </div>
            </div>
            <div class="mt-5 d-flex justify-content-between">
              <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('sales_amount') }}</span>
              <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ getPriceFormat(invoiceInfo.salesAmount) }}</span>
            </div>
            <div class="mt-2 d-flex justify-content-between">
              <span :class="`ml-5 ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('paid_online') }}</span>
              <span :class="isMobile ? 'font-12' : 'font-15'">{{ getPriceFormat(invoiceInfo.paidOnline) }}</span>
            </div>
            <div class="mt-2 d-flex justify-content-between">
              <span :class="`ml-5 ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('paid_by_card') }}</span>
              <span :class="isMobile ? 'font-12' : 'font-15'">{{ getPriceFormat(invoiceInfo.paidCard) }}</span>
            </div>
            <div class="mt-2 d-flex justify-content-between">
              <span :class="`ml-5 ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('paid_by_cash') }}</span>
              <span :class="isMobile ? 'font-12' : 'font-15'">{{ getPriceFormat(invoiceInfo.paidCash) }}</span>
            </div>
            <div class="mt-5 d-flex justify-content-between">
              <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('tip') }}</span>
              <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ getPriceFormat(invoiceInfo.tipAmount) }}</span>
            </div>
            <div class="mt-2 d-flex justify-content-between">
              <span :class="`ml-5 ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('paid_online') }}</span>
              <span :class="isMobile ? 'font-12' : 'font-15'">{{ getPriceFormat(invoiceInfo.tipOnline) }}</span>
            </div>
            <div class="mt-2 d-flex justify-content-between">
              <span :class="`ml-5 ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('paid_by_card') }}</span>
              <span :class="isMobile ? 'font-12' : 'font-15'">{{ getPriceFormat(invoiceInfo.tipCard) }}</span>
            </div>
            <div class="mt-2 d-flex justify-content-between">
              <span :class="`ml-5 ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('paid_by_cash') }}</span>
              <span :class="isMobile ? 'font-12' : 'font-15'">{{ getPriceFormat(invoiceInfo.tipCash) }}</span>
            </div>
          </div>
        </section>
      </vue-html2pdf>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1900"
        :pdf-quality="2"
        :manual-pagination="false"
        :html-to-pdf-options="htmlToPdfOptions"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        @progress="onProgress"
        ref="html2PdfOrder">
        <section slot="pdf-content">
          <div class="d-flex flex-column" v-if="paidOnlineOrderList && eventInfo && myInfo && myInfo.organizerInfo">
            <div class="d-flex justify-content-end mb-5">
              <img :src="assets.logo" :height="30" />
            </div>
            <div class="my-5 font-15">Eventbox / Am Strassanger 2 / 86470 Thannhausen</div>
            <div class="margin-top-2rem">
              <p class="mb-0 font-12">{{ myInfo.organizerInfo.name || "" }}</p>
              <p class="font-12">{{ myInfo.organizerInfo.address || "" }}</p>
              <p class="font-12 mb-0" v-if="myInfo.organizerInfo.taxCode">{{ $t('tax_code') }}: {{ myInfo.organizerInfo.taxCode }}</p>
              <p class="font-12" v-if="myInfo.organizerInfo.ustId">UST ID: {{ myInfo.organizerInfo.ustId }}</p>
            </div>
            <div class="margin-top-2rem">
              <p class="font-15 font-bold">{{ $t('transaction_overview_online_payment') }}</p>
              <p class="mb-0 font-12">{{ $t('credit_for_commission_services_see_customer_number') }}: {{ zeroPad(myInfo.customerNumber) }}</p>
              <p class="font-12" v-if="getTagIndex() === -1">{{ $t('invoice_number') }}: {{ zeroPad(eventInfo.invoiceNumber) }}</p>
              <p class="font-12" v-else>{{ $t('invoice_number') }}: {{ zeroPad(eventInfo.invoiceNumber) }}-{{ zeroPad(getTagIndex()) }}</p>
              <p class="mt-3 mb-0 font-12">{{ eventInfo.name }}</p>
              <p class="font-12">{{ $t('for_the_period') }}: {{ getDateStringFromTimestamp(eventInfo.startAt, 'DD.MM.YYYY') }} - {{ getDateStringFromTimestamp(eventInfo.endAt, 'DD.MM.YYYY') }}</p>
              <p class="mt-2 font-12" v-if="selectedTag">{{ $t('tag') }}: {{ selectedTag }}</p>
            </div>
            <div class="w-100 mt-20">
              <table class="w-100" border="1">
                <thead>
                  <tr>
                    <th style="width: 20%;" class="p-1 font-10">{{ $t('employee_name') }}</th>
                    <th style="width: 14%;" class="p-1 font-10">Order No.</th>
                    <th style="width: 18%;" class="p-1 font-10">{{ $t('paid') }}</th>
                    <th style="width: 14%;" class="p-1 font-10">{{ $t('tip') }}</th>
                    <th style="width: 14%;" class="p-1 font-10">{{ $t('amount') }}</th>
                    <th style="width: 20%;" class="p-1 font-10">{{ $t('date') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in paidOnlineOrderList.filter(element => (element.tag || '') === selectedTag)" :key="(item, index)">
                    <td class="p-1 font-8">{{ item.employeeName || '-' }}</td>
                    <td class="p-2 font-8">No. {{ item.orderNumber }}</td>
                    <td class="p-1 font-8">{{ item.paymentMethod === PAYMENT_TYPE_CARD ? $t('paid_by_card') : item.referenceId ? $t('paid_online') : '-'}}</td>
                    <td class="p-1 font-8">{{ item.tipPrice ? getPriceFormat(item.tipPrice) : '-' }}</td>
                    <td class="p-1 font-8">{{ getPriceFormat(item.totalPrice) }}</td>
                    <td class="p-1 font-8">{{ getDateStringFromTimestamp(item.createdAt) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div class="d-flex justify-content-center">
                <span class="mt-20 font-12">{{ $t('credit_made_in_few_days') }}</span>
              </div>
              <table class="w-100 mt-30">
                <thead>
                  <tr>
                    <th style="width: 33%;" class="font-12 font-bold">EVENTBOX</th>
                    <th style="width: 40%;" class="font-12 font-bold">Bankverbindung:</th>
                    <th style="width: 30%;" class="font-12 font-bold">Gerichtsstand</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="font-12">Mail: info@eventboxde.com</td>
                    <td class="font-12">Sparkasse Guenzburg-Krumbach</td>
                    <td class="font-12">Memmingen</td>
                  </tr>
                  <tr>
                    <td class="font-12"></td>
                    <td class="font-12">IBAN: DE50 7205 1840 0040 7969 55</td>
                    <td class="font-12">HRB 20618</td>
                  </tr>
                  <tr>
                    <td class="font-12"></td>
                    <td class="font-12">BIC: BYLADEM1GZK</td>
                    <td class="font-12">Steuernummer: 151/125/90053</td>
                  </tr>
                  <tr>
                    <td class="font-12 pt-1"></td>
                    <td class="font-12 pt-1"></td>
                    <td class="font-12 pt-1">UST ID: DE361515286</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </vue-html2pdf>
      <div class="mt-5 box-white" v-if="orderList">
        <LineChartGenerator
          class="mt-2"
          :style="chartStyle"
          :chart-data="chartData1"
          :chart-options="chartOptions1"
          v-if="orderList.length"
        />
        <div class="my-10 py-10 center" v-else>
          <p>{{ $t('no_data_found') }}</p>
        </div>
      </div>
      <div class="my-30 py-40 center" v-else>
        <b-spinner type="grow"></b-spinner>
        <p class="mt-5">{{ $t('alert_please_wait') }}</p>
      </div>
      <div class="mt-5" v-if="drinkList">
        <div class="mt-2 box-white vertical-center" v-for="(item, index) in drinkList.filter(element => element.tag === selectedTag)" :key="(item, index)">
          <span class="font-15 font-bold">{{ item.name }}</span>
          <div class="ml-auto mr-5 d-flex flex-column">
            <span class="ml-auto font-12 alpha-07">{{ $t('sold_drinks') }}</span>
            <span class="ml-auto mt-2 font-15">{{ `${item.soldQuantity} / ${item.totalQuantity}` }}</span>
          </div>
          <RadialProgressBar
            :completed-steps="item.soldQuantity"
            :total-steps="item.totalQuantity"
            :diameter="60"
            :innerStrokeWidth="4"
            :strokeWidth="4"
            innerStrokeColor="#D3D3D3"
            start-color="#A10559"
            stop-color="#A10559"
          >
            <span class="font-15 font-bold" style="color: #A10559;">{{ item.soldPercent }}</span>
          </RadialProgressBar>
        </div>
      </div>
      <div class="my-5 d-flex justify-content-end" v-if="paidOnlineOrderList.length > 0">
        <v-btn class="mt-5 button-drink" @click="generatePaidOnlineOrders()">
          <span class="px-10">{{ $t('export') }}</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .search-box {
    padding: 10px 20px !important;
    border-radius: 20px !important;
    border: 1px solid #45154D !important;
    background-color: white !important;
  }
  .search-box:focus { 
    outline: none !important;
  }
</style>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

import RadialProgressBar from 'vue-radial-progress';
import VueHtml2pdf from 'vue-html2pdf';

import { firestore } from '../../../main';
import { onSnapshot,query, collection, where } from 'firebase/firestore';
import { getDecimalFormat, getPriceFormat, getDateStringFromTimestamp, toFileName, getDateStringFromDate, zeroPad, showLoading } from '../../../functions';

import arrow_down from '@/assets/images/arrow_down.png';
import arrow_up from '@/assets/images/arrow_up.png';
import download from '@/assets/images/download.png';
import logo from '@/assets/images/logo_for_invoice.png';

export default {
  name: 'DrinkDashboard',
  components: {
    LineChartGenerator,
    RadialProgressBar,
    VueHtml2pdf
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    myInfo() {
      return this.$store.state.myInfo;
    },
    eventList() {
      return this.$store.state.eventList;
    },
    quantityKeyList() {
      const list = [];
      list.push({
        key: this.$store.state.myId,
        name: this.myInfo ? this.myInfo.userName : ''
      });
      const employeeList = this.$store.state.employeeList.filter(element => element.inUse === true && element.role === 2 && element.eventId === this.$route.params.eventId);
      employeeList.sort((a, b) => (a.name > b.name) ? 1 : -1);
      employeeList.forEach(employeeInfo => {
        list.push({
          key: employeeInfo.name,
          name: employeeInfo.name
        });
      });
      return list;
    },
    chartStyle() {
      return {
        height: `${this.isMobile ? 200 : 400}px`,
        position: 'relative'
      };
    },
  },
  watch: {
    eventList() {
      this.checkEvent();
    },
    quantityKeyList() {
      this.generateOrderChartData();
    },
    keyword() {
      this.generateOrderChartData();
    },
    selectedTag() {
      this.generateOrderChartData();
    }
  },
  data() {
    return {
      PAYMENT_TYPE_CARD: 'Card',
		  PAYMENT_TYPE_CASH: 'Cash',
      assets: {
        arrow_down,
        arrow_up,
        download,
        logo
      },
      htmlToPdfOptions: {
        margin: 10,
        filename: 'Invoice',
        image: {
          type: 'jpeg',
          quality: 1.0
        },
        html2canvas: {
          scale: 1,
          useCORS: true
        }
      },
      chartData1: {
        labels: [],
        datasets: [
          {
            label: this.$t('sales_amount'),
            backgroundColor: '#A10559',
            borderColor: '#A10559',
            borderWidth: 1,
            cubicInterpolationMode: 'monotone',
            data: []
          }
        ]
      },
      chartOptions1: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              callback: function(value, index, ticks) {
                return getPriceFormat(value, true);
              }
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';
                if (label) {
                    label += ': ';
                }
                if (context.parsed.y !== null) {
                    label += getPriceFormat(context.parsed.y);
                }
                return label;
              }
            }
          }
        }
      },
      keyword: '',
      salesList: [],
      eventInfo: null,
      selectedTag: '',
      invoiceInfo: null,
      orderList: null,
      paidOnlineOrderList: [],
      payoutList: [],
      drinkList: null,
      snapOrderList: null,
      snapPayoutList: null,
      snapDrinkList: null,
      loader: null
    };
  },
  mounted() {
    if (this.$route.params.eventId) {
      if (this.myInfo) {
        if (this.myInfo.userType === this.USER_ORGANIZER) {
          this.getOrderListTask();
          this.getDrinkListTask();
          this.checkEvent();
        } else {
          this.$toast.error('No permission!!');
          this.$router.push('/');
        }
      } else {
        this.$toast.info(this.$t('alert_sign_in'));
        this.$router.push('/');
      }
    } else {
      this.$router.push('/');
    }
  },
  destroyed() {
    if (this.snapOrderList) {
      this.snapOrderList();
    }
    if (this.snapPayoutList) {
      this.snapPayoutList();
    }
    if (this.snapDrinkList) {
      this.snapDrinkList();
    }
  },
  methods: {
    getDecimalFormat,
    getPriceFormat,
    getDateStringFromTimestamp,
    zeroPad,
    checkEvent() {
      if (this.eventList) {
        this.eventInfo = this.eventList.find(element => element.eventId === this.$route.params.eventId);
        if (this.eventInfo) {
          if (this.eventInfo.tag) {
            this.selectedTag = this.eventInfo.tag;
          }
          if (this.eventInfo.userId !== this.$store.state.myId) {
            this.$toast.error('This is not your event!!');
            this.$router.push('/my_events/1');
          }
        } else {
          this.$toast.error('Invalid event!');
          this.$router.push('/my_events/1');
        }
      }
    },
    generateInvoice(employeeName) {
      this.invoiceInfo = this.salesList.find(element => element.employeeName === employeeName);
      if (this.invoiceInfo && this.eventInfo) {
        this.loader = showLoading(this, 0, true);
        const tagIndex = this.getTagIndex();
        const tagName = tagIndex === -1 ? '' : `_${tagIndex}`;
        this.htmlToPdfOptions.filename = `${toFileName(employeeName)}_${toFileName(this.eventInfo.name)}${tagName}_${getDateStringFromDate()}`;
        this.$refs.html2PdfInvoice.generatePdf();
      }
    },
    generatePaidOnlineOrders() {
      if (this.paidOnlineOrderList.length > 0) {
        this.loader = showLoading(this, 0, true);
        const tagIndex = this.getTagIndex();
        const tagName = tagIndex === -1 ? '' : `_${tagIndex}`;
        this.htmlToPdfOptions.filename = `${toFileName(this.eventInfo.name)}${tagName}_Drink_${getDateStringFromDate()}`;
        this.$refs.html2PdfOrder.generatePdf();
      }
    },
    getTagIndex() {
      if (this.eventInfo.tagList && this.selectedTag) {
        return this.eventInfo.tagList.findIndex(element => element === this.selectedTag);
      }
      return -1;
    },
    onProgress(progress) {
      if (progress === 100 && this.loader) {
        this.loader.hide();
        this.loader = null;
      }
    },
    generateOrderChartData() {
      if (!this.drinkList || !this.payoutList || !this.quantityKeyList || !this.orderList) {
        return;
      }
      const labels = [];
      const data = [];
      const salesList = [];
      const totalInfo = {
        quantityKey: '',
        employeeName: this.$t('total'),
        expanded: false,
        salesAmount: 0,
        paidOnline: 0,
        paidCard: 0,
        paidCash: 0,
        handlingFee: 0,
        tipAmount: 0,
        tipOnline: 0,
        tipCard: 0,
        tipCash: 0,
        payoutAmount: 0,
        alreadyPaid: 0,
        pending: 0,
        notPaid: 0,
        soldQuantity: 0,
        totalQuantity: 0
      };
      const keyword = this.keyword.toLowerCase();
      if (!keyword) {
        this.payoutList.filter(element => (element.tag || '') === this.selectedTag).forEach(payoutInfo => {
          if (payoutInfo.paidAt) {
            totalInfo.alreadyPaid += payoutInfo.payoutAmount;
          } else {
            totalInfo.pending += payoutInfo.payoutAmount;
          }
        });
        if (this.drinkList) {
          this.drinkList.filter(element => element.tag === this.selectedTag && element.soldQuantityMap && element.totalQuantityMap).forEach(drinkInfo => {
            totalInfo.soldQuantity += drinkInfo.soldQuantity;
            totalInfo.totalQuantity += drinkInfo.totalQuantity;
          });
        }
        salesList.push(totalInfo);
      }
      this.quantityKeyList.filter(element => !keyword || element.name.toLowerCase().includes(keyword)).forEach(info => {
        const saleInfo = {
          quantityKey: info.key,
          employeeName: info.name,
          expanded: false,
          salesAmount: 0,
          paidOnline: 0,
          paidCard: 0,
          paidCash: 0,
          tipAmount: 0,
          tipOnline: 0,
          tipCard: 0,
          tipCash: 0,
          soldQuantity: 0,
          totalQuantity: 0
        };
        if (this.drinkList) {
          this.drinkList.filter(element => element.tag === this.selectedTag && element.soldQuantityMap && element.totalQuantityMap).forEach(drinkInfo => {
            saleInfo.soldQuantity += drinkInfo.soldQuantityMap[info.key] || 0;
            saleInfo.totalQuantity += drinkInfo.totalQuantityMap[info.key] || 0;
          });
        }
        salesList.push(saleInfo);
      });
      var value = 0;
      this.orderList.filter(element => (element.tag || '') === this.selectedTag && (!keyword || (element.employeeName || '').toLowerCase().includes(keyword))).forEach(info => {
        const date = getDateStringFromTimestamp(info.createdAt, 'HH:00');
        if (!labels.includes(date)) {
          if (labels.length > 0) {
            data.push(value);
            value = 0;
          }
          labels.push(date);
        }
        value += info.drinkPrice;
        const key = info.employeeName || this.$store.state.myId;
        const employeeName = info.employeeName || this.myInfo.userName;
        const dataInfo = salesList.find(element => element.quantityKey === key) || {
          quantityKey: key,
          employeeName: employeeName,
          expanded: false,
          salesAmount: 0,
          paidOnline: 0,
          paidCard: 0,
          paidCash: 0,
          tipAmount: 0,
          tipOnline: 0,
          tipCard: 0,
          tipCash: 0,
          soldQuantity: 0,
          totalQuantity: 0,
          needAdd: true
        };
        totalInfo.salesAmount += info.drinkPrice;
        totalInfo.tipAmount += info.tipPrice;
        totalInfo.handlingFee += info.handlingFee;
        dataInfo.salesAmount += info.drinkPrice;
        dataInfo.tipAmount += info.tipPrice;
        if (info.paymentMethod === this.PAYMENT_TYPE_CARD) {
          totalInfo.paidCard += info.drinkPrice;
          totalInfo.tipCard += info.tipPrice;
          dataInfo.paidCard += info.drinkPrice;
          dataInfo.tipCard += info.tipPrice;
        } else if (info.paymentMethod === this.PAYMENT_TYPE_CASH) {
          totalInfo.paidCash += info.drinkPrice;
          totalInfo.tipCash += info.tipPrice;
          dataInfo.paidCash += info.drinkPrice;
          dataInfo.tipCash += info.tipPrice;
        } else if (info.referenceId) {
          totalInfo.paidOnline += info.drinkPrice;
          totalInfo.tipOnline += info.tipPrice;
          dataInfo.paidOnline += info.drinkPrice;
          dataInfo.tipOnline += info.tipPrice;
        }
        if (dataInfo.needAdd === true) {
          salesList.push(dataInfo);
        }
      });
      data.push(value);
      if (!keyword) {
        totalInfo.payoutAmount = totalInfo.salesAmount - totalInfo.paidCash - totalInfo.handlingFee + totalInfo.tipOnline + totalInfo.tipCard;
        totalInfo.notPaid = totalInfo.payoutAmount - totalInfo.alreadyPaid - totalInfo.pending;
      }
      this.salesList = salesList;
      this.chartData1.labels = labels;
      this.chartData1.datasets[0].data = data;
    },
    getOrderListTask() {
      if (this.snapOrderList) {
        return;
      }
      const q = query(collection(firestore, 'drinkOrder'), where('eventId', '==', this.$route.params.eventId), where('paymentMethod', '!=', ''));
      this.snapOrderList = onSnapshot(q, querySnapshot => {
        const orderList = [];
        querySnapshot.forEach(doc => orderList.push(doc.data()));
        orderList.sort((a, b) => (a.createdAt.seconds > b.createdAt.seconds) ? 1 : -1);
        this.paidOnlineOrderList = orderList.filter(element => element.completedAt && element.paymentMethod !== this.PAYMENT_TYPE_CASH);
        this.paidOnlineOrderList.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : -1);
        this.orderList = orderList;
        this.generateOrderChartData();
      });
    },
    getPayoutListTask() {
      if (this.snapPayoutList) {
        return;
      }
      const q = query(collection(firestore, 'drinkPayout'), where('eventId', '==', this.$route.params.eventId));
      this.snapPayoutList = onSnapshot(q, querySnapshot => {
        const payoutList = [];
        querySnapshot.forEach(doc => payoutList.push(doc.data()));
        this.payoutList = payoutList;
        this.generateOrderChartData();
      });
    },
    getDrinkListTask() {
      if (this.snapDrinkList) {
        return;
      }
      const q = query(collection(firestore, 'drink'), where('eventId', '==', this.$route.params.eventId), where('deletedAt', '==', null));
      this.snapDrinkList = onSnapshot(q, querySnapshot => {
        const drinkList = [];
        var soldQuantitySum = 0;
        var totalQuantitySum = 0;
        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (data.category !== 'Longdrinks') {
            const soldQuantity = data.soldQuantity || 0;
            const totalQuantity = data.totalQuantity || 0;
            soldQuantitySum += soldQuantity;
            totalQuantitySum += totalQuantity;
            drinkList.push({
              name: data.name,
              tag: data.tag || '',
              soldQuantity: soldQuantity,
              totalQuantity: totalQuantity,
              soldQuantityMap: data.soldQuantityMap || {},
              totalQuantityMap: data.totalQuantityMap || {},
              soldPercent: totalQuantity > 0 ? `${Math.round(soldQuantity / totalQuantity * 100)}%` : '0%'
            });
          }
        });
        drinkList.sort((a, b) => (b.totalQuantity > 0 ? b.soldQuantity / b.totalQuantity : 0) > (a.totalQuantity > 0 ? a.soldQuantity / a.totalQuantity : 0) ? 1 : -1)
        const totalInfo = {
          name: this.$t('total'),
          soldQuantity: soldQuantitySum,
          totalQuantity: totalQuantitySum,
          soldPercent: totalQuantitySum > 0 ? `${Math.round(soldQuantitySum / totalQuantitySum * 100)}%` : '0%'
        };
        this.drinkList = [totalInfo].concat(drinkList);
        this.generateOrderChartData();
      });
    }
  }
};
</script>